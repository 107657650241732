* {
  margin: 0;
  box-sizing: border-box;
}

body {
  padding: 1em 0;
  background-color: black;
}

/* NavBar.js */

.top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 4px 4px -1px white;
}

.top a {
  text-decoration: none;
  color: #ffffff;
}

.title {
  font-family: Snell Roundhand, cursive;
  color: #ffffff;
  font-size: calc((2.5 - 1) * 2vw + 1rem);
  margin: 0 -1.25em;
}

.logo {
  max-width: 10em;
  font-size: calc((2 - 1) * 1vw + 0.5rem);
}

.drawerLink {
  letter-spacing: 2px;
  box-shadow: 0px 0px 8px 0px white;
  font-family: Snell Roundhand, cursive;
  display: flex;
  justify-content: center;
  border: 1px solid white;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 16px;
  font-size: 32px;
  padding: 16px;
  color: white;
  background-color: black;
}

.drawerLink:hover {
  color: red;
}

.drawerSection {
  font-family: Snell Roundhand, cursive;
  color: white;
  text-align: center;
  font-size: 36px;
  margin: -16px auto 16px;
  width: 300px;
  /* border-bottom: 2px solid white; */
  box-shadow: 0 4px 4px -3px white;
}

#drawerButton {
  padding: 0.5em 1.5em;
  height: 100%;
}

.ant-drawer-title {
  color: white;
  text-align: center;
}

.anticon {
  color: white;
}

.fa-brands {
  justify-content: space-between;
  max-width: calc((3 - 1) * 1vw + 0.5rem);
  text-decoration: none;
  font-size: calc((3 - 1) * 1vw + 0.5rem);
}

.nav-bar {
  width: 100%;
  display: flex;
  background: #000000;
  box-shadow: 0em 0em 8px 0px white;
  justify-content: space-evenly;
}

.nav-bar a {
  color: white;
  text-decoration: none;
  padding: 0.5em 0px;
}

.nav-bar a:hover {
  text-shadow: 1px 1px 3px red, 3px 3px 3px white;
}

/* Home.js */

.home {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

/* Carousel.js */

.Carousel {
  padding: 1em 1em;
  margin: 1em 0em;
  width: 100%;
  color: white;
  display: flex;
  overflow-x: scroll;
  background-color: #000000;
}

.Carousel img {
  padding: 0.25em 0.25em 0.25em;
  width: 125px;
  height: 125px;
  border-radius: 0.5em;
}

.card {
  min-width: 275px;
  margin: 0em 1em;
  border: 1px solid white;
  display: flex;
  text-align: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px white;
  border-radius: 0.5em;
}

.cardBody p {
  font-size: 16px;
  margin: 0em 1em;
}

.Carousel::-webkit-scrollbar {
  background: #000000;
  height: 4px;
}

.Carousel::-webkit-scrollbar-thumb {
  border-radius: 2em;
  background: #ffffff;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 12.28));
  }
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slide-track:hover,
.slide-track:focus {
  animation-play-state: paused;
}

/* About.js */

.About {
  justify-content: center;
  align-items: center;
  background-image: url("./images/Headshot.jpeg");
  background-size: cover;
  height: 750px;
  margin: 1em 2em;
  width: 45%;
  border-radius: 0.5em;
  box-shadow: 0em 0em 8px 0px rgb(255, 255, 255);
}

.front {
  position: relative;
  border-radius: 0.5em;
}

#headshot {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.5em;
  margin-bottom: -0.25em;
}

.frontCap {
  text-align: center;
  position: absolute;
  top: 0;
  background-color: rgb(0, 0, 0, 1);
  width: 55%;
  font-size: 25px;
  padding: 3em 0em;
  border-radius: 0.5em 0em 0.5em 0em;
  color: #ffffff;
  box-shadow: 0em 0em 1px 0px rgb(255, 255, 255);
}

.frontCap p {
  padding: 0em 1em;
}

/* MotD.js */

.motd {
  justify-content: center;
  align-items: center;
  background-color: #000000;
  width: 48%;
  color: #ffffff;
}

.meal {
  border-radius: 0.5em;
  box-shadow: 0em 0em 8px 0px rgb(255, 255, 255);
  margin: 0em 2em;
  position: relative;
}

.mealTitle {
  text-align: center;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1;
  width: 100%;
  box-shadow: 0em 0em 2px 0px rgb(255, 255, 255);
  border-radius: 0.5em 0.5em 0em 0em;
}

.motd h1 {
  margin: auto;
  width: 75%;
  background-color: rgb(0, 0, 0, 0.7);
}

#mealPic {
  border-radius: 0.5em;
  width: 100%;
  height: 670px;
  margin-bottom: -0.25em;
}

.mealText {
  text-align: center;
  box-shadow: 0em 0em 8px 0px rgb(255, 255, 255);
  background-color: rgb(0, 0, 0, 0.7);
  width: 100%;
  border-radius: 0em 0em 0.5em 0.5em;
}

.mealCap {
  padding: 0.25em 2em;
  margin: 0.25em 0em;
}

.mealLink {
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 0.5em;
}

/* Create.js */

.create {
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
}

.upTitle {
  margin: auto;
}

.upName {
  margin: auto;
}

/* MealPreps.js */

.MealPreps {
  text-align: center;
  margin: 2em auto;
  color: white;
}

.MealPreps h1 {
  font-family: Snell Roundhand, cursive;
  font-size: 60px;
}

.MealPreps h2 {
  font-size: 30px;
  word-spacing: 4px;
  /* letter-spacing: 3px; */
  font-family: Snell Roundhand, cursive;
}

#mealTag {
  font-size: 54px;
  width: 7em;
  margin: 0 auto 8px;
  box-shadow: 0 6px 6px -6px #fff;
  /* border-bottom: 4px solid white; */
  font-family: Snell Roundhand, cursive;
}

#mealTag2 {
  /* font-family: Snell Roundhand, cursive; */
  font-size: 20px;
  margin-bottom: 0.5em;
}

.MealPrepOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 5em;
  justify-content: center;
  margin: 3em 0;
}

.mealOpt {
  border-radius: 8px;
  margin: 0 1em;
  box-shadow: 0 0px 6px 0px #fff;
}

.mealOpt img {
  max-width: 450px;
  height: 300px;
  border-radius: 0.5em;
  /* box-shadow: 0em 0em 8px 0px rgb(255, 255, 255); */
}

.mealOpt p {
  box-shadow: 0 6px 6px -6px #fff;
  width: 300px;
  margin: 1em auto 0;
  font-size: 28px;
  /* padding: 0 0.5em; */
}

.mealOpt form {
  align-items: center;
  margin: 1em auto;
  display: flex;
  justify-content: space-evenly;
  font-size: 22px;
}

.mealOpt input {
  text-align: center;
  width: 4em;
  background: none;
  color: #fff;
  border: 1px solid white;
  padding: 0.5em;
  border-radius: 0.25em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mealOpt button {
  background-color: #fff;
  color: black;
  font-size: 16px;
  border-radius: 0.25em;
  margin-top: 2em;
  box-shadow: 0 0px 2px 1px rgb(255, 0, 0);
  padding: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
}

.ant-modal-content {
  text-align: center !important;
}

/* Merch.js and Recipes.js */

.bigCard img {
  padding: 0.25em 0.25em 0em;
  width: 275px;
  height: 275px;
  border-radius: 0.5em;
  padding-right: 6px;
}

.bigCard {
  display: block;
  /* height: 450px; */
  padding-bottom: 1em;
  width: 275px;
  margin: 1em 2em;
  border: 1px solid white;
  text-align: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px white;
  border-radius: 0.5em;
  color: #ffffff;
}

.bigCard h4 {
  font-size: 16px;
  margin: 0.5em 1em;
}

.bigCard p {
  padding: 0 1em;
}

.bigCard button {
  margin: 0.5em 0em 0.75em;
  box-shadow: 0px 0px 4px 0px white;
  background: #000000;
  color: #ffffff;
  cursor: pointer;
}

.bigCard ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Admin.js */

.uploadSection {
  margin: 2em 0;
  text-align: center;
  color: white;
}

.listOfPreps {
  color: white;
}

.listOfPreps a {
  text-decoration: none;
}

.prepOpt {
  background-color: white;
  box-shadow: 0 0px 2px 1px rgb(255, 0, 0);
  color: black;
  width: 300px;
  margin: 1em auto;
  padding: 0.5em 0;
}

/* Contact.js */

.main {
  margin: 1em auto;
  color: #fff;
}

th,
td {
  border: 1px solid rgb(255, 255, 255);
  padding: 20px;
}

#fcf-form {
  display: block;
  color: white;
}

.fcf-body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  padding: 30px;
  padding-bottom: 10px;
  border-radius: 0.25rem;
  max-width: 100%;
}

.fcf-form-group {
  margin-bottom: 1rem;
}

.fcf-input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.fcf-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  outline: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fcf-form-control:focus {
  border: 1px solid #313131;
}

select.fcf-form-control[size],
select.fcf-form-control[multiple] {
  height: auto;
}

textarea.fcf-form-control {
  font-family: -apple-system, Arial, sans-serif;
  height: auto;
}

label.fcf-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.fcf-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .fcf-btn {
    transition: none;
  }
}

.fcf-btn:hover {
  color: #212529;
  text-decoration: none;
}

.fcf-btn:focus,
.fcf-btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.fcf-btn-primary {
  color: #fff;
  background-color: rgb(207, 33, 33);
  border-color: rgb(207, 33, 33);
}

.fcf-btn-primary:hover {
  color: #fff;
  background-color: rgb(171, 24, 24);
  border-color: rgb(171, 24, 24);
}

.fcf-btn-primary:focus,
.fcf-btn-primary.focus {
  color: #fff;
  background-color: rgb(171, 24, 24);
  border-color: rgb(171, 24, 24);
  box-shadow: 0 0 0 0.2rem rgb(207, 33, 33);
}

.fcf-btn-lg,
.fcf-btn-group-lg > .fcf-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.fcf-btn-block {
  display: block;
  width: 100%;
}

.fcf-btn-block + .fcf-btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].fcf-btn-block,
input[type="reset"].fcf-btn-block,
input[type="button"].fcf-btn-block {
  width: 100%;
}

/* Download Pages */

.mainRunner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mainRunner a {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  margin: 25px 0px 25px;
}

.text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.downloadRec {
  background-color: white;
  color: black;
  border: 2px solid #000000;
}

.downloadRec:hover {
  background-color: #555555;
  color: white;
}

/* Footer.js */

.footer {
  margin: 1em;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.footer a {
  color: #ffffff !important;
  border-bottom: 1px solid #ffffff;
}

.ending {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .title {
    margin-right: -0.75em;
  }

  #drawerButton {
    margin-left: -1em;
    padding: 0.5em 1em;
  }

  .Carousel img {
    padding: 0.25em 0.25em 0.25em;
    width: 100px;
    height: 100px;
    border-radius: 0.5em;
  }

  .card {
    min-width: 210px;
  }

  .cardBody p {
    font-size: 10px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-210px * 5.77));
    }
  }

  .slide-track {
    animation: scroll 22s linear infinite;
  }

  .About {
    background-size: cover;
    max-height: 363px;
    max-width: 330px;
  }

  #headshot {
    height: 365px;
  }

  .frontCap {
    padding: 3em 0;
  }

  .frontCap p {
    font-size: 12px !important;
  }

  .motd {
    margin-top: 2em;
    width: 100% !important;
    margin-bottom: 7em;
  }

  .motd h1 {
    width: 80%;
  }

  #mealPic {
    height: 363px !important;
  }

  .mealText {
    margin-bottom: -5em;
    background-color: rgb(0, 0, 0, 1);
  }

  .mealCap {
    padding: 0 2em;
  }
}

@media only screen and (max-width: 1200px) {
  .About {
    height: 490px !important;
    width: 440px;
  }

  .frontCap {
    font-size: 17px !important;
  }

  .motd {
    width: 505px;
  }

  #mealPic {
    max-height: 415px;
  }

  .footer {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .About {
    height: 685px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .frontCap {
    font-size: 25px;
  }

  #mealPic {
    height: 608px;
  }
}
